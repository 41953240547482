.navbar {
    background-color: #f25d9c;
    /* padding-bottom: 50px; */
    /* padding: 20px; */
    height: 100vh;
    max-height: 100vh;
    min-width: 190px;
    max-width: 15vw;
}

.navbarContent {
    /* padding: 15px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.topLinks {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.bottomLinks{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.link {
    min-height: 50px;
    display: flex;
    justify-content: initial;
    align-items: center;
    padding-left: 10px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    /* margin-left: 10px; */
}

.link:hover {
    background-color:#bf326d;
}

/* .link:disabled {
    background-color:#bf326d;
    color: red;
} */

/* .linkAtag{
    text-decoration: none;
    color: black;
    font-weight: bold;
    margin-left: 10px;
} */

.logOutbutton {
    
}