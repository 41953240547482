.HomeContent {
    width: 85vw;
    height: 90vh;
    max-height: 95vh;
    /* Enable scrolling when content overflows */
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

button {
    /* background-color:  #3a76b5; */
    background-color: #bf326d;
    white-space: nowrap;
    overflow: hidden; /* Hide the overflowed text */
    width: auto;
    min-width: 100px;
    color: black;
    font-weight: bold;
}

button:disabled {
    background-color: gray; /* Change the background color when disabled */
    color: white; /* Change the text color when disabled */
    cursor: not-allowed; /* Change the cursor to indicate it's disabled */
}

.standardButton {
    background-color: #bf326d;
    white-space: nowrap;
    overflow: hidden; /* Hide the overflowed text */
    width: auto;
    min-width: 100px;
    min-height: 40px;
    color: black;
    font-weight: bold;
    border: none;
    text-align: center;
    text-decoration: none;
    /* padding: 15px 32px; */
    margin: 15px;
    /* padding: 5px; */
    display: inline-block;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s; /* Add a transition for a smooth animation */
}

.standardButton:hover {
    transform: scale(1.1); /* Apply a scale transform to create the pop-up effect */
  }

.standardButton:disabled {
    background-color: #d0afbd; /* Change the background color when disabled */
    color: white; /* Change the text color when disabled */
    cursor: not-allowed; /* Change the cursor to indicate it's disabled */
}

input {
    align-self: center;
}

/* input {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    color: #333;
    outline: none;
    height: 15px;
    min-height: 10px;
    margin-top: 5px;
}

input:focus {
    border: 2px solid #007bff;
} */

label{
    font-weight: 500;
}

.divContainerSourceInput, .divContainerDestinationInput {
    margin: 10px;
}

.connectSourceDiv {
    margin: 10px;
    margin-bottom: 20px;
}

.selectMappingDest {
    min-height: 30px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    color: #333;
    outline: none;
    /* height: 40px !important; */
}

.jsonMappingDiv {
    border: 1px solid gray;
    border-radius: 4px;
    min-height: 100px;
    max-height: 300px;
    scroll-behavior: smooth;
    background-color: #333;
    color: white;
    font-weight: 300;
    overflow: auto;
}

.listConectionsContainer {
    border: 1px solid gray;
    min-width: 30vw;
    width: 70vw;
    padding: 30px;
    border-radius: 20px;
    margin: 0 auto;
    max-height: 65vh;
    margin-bottom: 30px;
    margin-top: 30px;
    /* margin-bottom: 30px; */
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    overflow: auto; 
}

.listConectionsElement {
    border: 1px solid gray;
    border-radius: 20px;
    margin-bottom: 15px;
    max-width: 95%;
    padding: 0 20px;
    display:inline-flex;
    justify-content: space-between;
    width: 100%;
}

.listConectionsElement:last-child{
    margin-bottom: 0;
}

.newConnectionsSection {
    display: flex;
    justify-content: flex-end;
    padding: 0 80px;
}

.skeletonHome{
    display: flex;
    justify-content: center;
}