/* Create two equal columns that floats next to each other */
.column {
    float: left;
    width: 50%;
    padding: 10px;
    height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
/* .row:after {
content: "";
display: table;
clear: both;
} */

.row {
    display: flex;
}

.keyValueMapperContainer {
    display: flex;
    align-items: center;
    min-width: 40vw;
    justify-content: space-between;
    margin-bottom: 20px;
}

.keyValueMapperContainer:last-child {
    margin-bottom: 0px
}