.DestinationContent {
    width: 85vw;
    height: 95vh;
    max-height: 95vh;
    /* Enable scrolling when content overflows */
    overflow: auto; 
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.inputMappingDest {
    align-self: center;
    margin: 0;
}