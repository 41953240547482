.googleButton {
    min-width: 200px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    color: black;
    border: 2px solid lightgrey;
    border-radius: 10px;
    font-weight: bold;
}

.googleButton:hover {
    background-color: lightgray;
    cursor: pointer;
}