.ListContent {
    width: 85vw;
    height: 95vh;
    max-height: 95vh;
    /* Enable scrolling when content overflows */
    overflow: auto; 
}

.linkAtagList{
    text-decoration: none;
    color: black;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
}

.linkAtagList:hover {
    color: rgb(68, 159, 220);
}

.listElement {
    border: 1px solid gray;
    border-radius: 30px;
    width: 80vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
}

.listBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}