form {
  /* width: 300px; */
  margin: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  height: 30px;
  border-color: gray;
  border-width: 1px;
  padding: 5px;
}

button {
  width: 100px;
  height: 30px;
  background-color: lightblue;
  color: white;
  border-radius: 5px;
  margin: 10px;
}
  

.header {
    text-align: "center";
}

.mainDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.signInContent {
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signInInsiderContainer {
  border: 1px solid gray;
  width: fit-content;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: fit-content;
  height: 30vh;
}
