.container {
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    text-align: left;
    /* top: 8px; */
}
.checkbox {
    display: none;
}
.label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
}
.inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
    float: left;
    width: 50%;
    height: 25px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
}
.inner:before {
    content: "";
    padding-left: 10px;
    background-color: #060;
    color: #fff;
    }
.inner:after {
    content: "";
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
}
.switch {
    display: block;
    /* width: 24px; */
    width: 15px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    /* right: 40px; */
    right: 50px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
    margin-left: 0;
}
.checkbox:checked + .label .switch {
    right: 0px;
}